
  import SliderTabs from '@/components/SliderTabs.vue'
  import { EcosystemId, IEcosystem } from '@/ecosystem'

  import Vue from 'vue'
  import { mapActions } from 'vuex'
  import AwaitLock from 'await-lock'
  import { ethers } from 'ethers'

  import { ERC20_ABI,
           LAUNCHPAD_FACTORY_ABI,
           APPROVE_AMOUNT } from '@/constants'
  import { ChainId } from '@/ecosystem'
  import { equalsInsensitive, delay } from '@/utils'

  import { PresaleData } from '@/types'

  // These token symbols will not be allowed
  const symbolBlacklist = ['TOAD', 'PAD', 'USDC', 'USDT', 'DAI', 'BNB', 'BUSD', 'ETH', 'BTC', 'GLMR', 'MOVR', 'XRP', 'XMR', 'DOT', 'ADA', 'SOLAR']

  export default Vue.extend ({
    components: { SliderTabs },
    data: () => ({
      valid: true,
      hostname: <string> '',

      presaleContractAddress: <string | null> null,
      tokenName: <string | null> null,
      tokenSymbol: <string | null> null,
      tokenSupply: <ethers.BigNumber | null> null,
      tokenDecimals: <number | null> null,
      userTokenAllowance: <ethers.BigNumber | null> null,
      userTokenBalance: <ethers.BigNumber | null> null,
      enableReferrals: <boolean> true,

      tokenContractAddress: '',
      tokenContractError: <string | null> null,

      presaleHardCap: <string> '',
      presaleSoftCap: <number | null> null,
      presaleDuration: <string> '',
      presaleTokenAmount: <string> '',
      presalePrice: <string> '',
      presaleMaxContribution: <string> '',

      // Custom data to be stored in a json string
      logoUrl: '',
      telegramUrl: '',
      discordUrl: '',
      websiteUrl: '',
      isPublic: <boolean> true,

      // Ecosystem-specific
      currentChain: '',
      backgroundImage: '',

      nameRules: [
        (v: any) => !!v || 'Token name is required',
        (v: any) => (v && v.length <= 20) || 'Token name cannot be longer than 20 characters',
      ],
      symbolRules: [
        (v: any) => !!v || 'Token symbol is required',
        (v: any) => (v && v.length <= 8) || 'Token symbol cannot be longer than 8 characters',
        (v: any) => (!symbolBlacklist.includes(v)) || 'Please don\'t create tokens that falsely represent other projects'
      ],
      supplyRules: [
        (v: any) => !!v || 'Choose the max supply of your token',
        (v: any) => (v && v.length <= 13 && parseFloat(v) <= 1000000000000) || 'Let\'s be reasonable, you don\'t need more than a trillion tokens',
        (v: any) => (v && parseInt(v) != 0) || '0 tokens is not enough',
        (v: any) => (parseFloat(v) % 1 == 0 && parseFloat(v) > 0 && /[0-9]/.test(v)) || 'Input a positive integer number'
      ],
      durationRules: [
        (v: any) => !!v || 'You need to specify the presale duration',
        (v: any) => (v && v.length <= 3 && parseFloat(v) <= 168 && parseFloat(v) >= 12) || 'Choose a value between 12 and 168 hours',
        (v: any) => (parseFloat(v) % 1 == 0 && /[0-9]/.test(v)) || 'Input a positive integer number'
      ],
      contractAddressRules: [
        (v: any) => !!v || 'Specify your token\'s contract address',
        (v: any) => (v.length == 42 && v.slice(0, 2) == '0x') || 'Not a valid contract address.'
      ],
      maxContributionRules: [
        (v: any) => !!v || 'Specify the maximum contribution per user (0 for infinite)',
        (v: any) => (parseFloat(v) >= 0 && (/[0-9]*(?:\.[0-9]*)?/.test(v))) || 'Input a valid positive number (or 0 for infinite)'
      ],
      websiteUrlRules: [
          (v: any) => (!v || /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(v)) || 'Enter a valid website URL, or leave empty if you don\'t have one'
        ],
      logoUrlRules: [
          (v: any) => (!v || /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(v)) || 'Enter a valid link to your logo, or leave empty if you don\'t have one'
        ],
      telegramUrlRules: [
          (v: any) => (!v || /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/.test(v)) || 'Enter a valid Telegram invite link, or leave empty if you don\'t have one'
        ],
      discordUrlRules: [
          (v: any) => (!v || /(https?:\/\/)?(www[.])?discord\.(com\/invite|gg)\/([a-zA-Z0-9_-]*)\/?$/.test(v)) || 'Enter a valid Discord invite link, or leave empty if you don\'t have one'
        ],
      validationCheckbox: false,
      active: true,
      syncLock: new AwaitLock()
    }),
    created () {
      window.onstorage = () => {
        this.$store.commit('setUserProfile')
      };
      this.hostname = window.location.host
    },
    computed: {
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
        }
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      address(): string {
        return this.$store.state.address
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      tokenContract(): ethers.Contract | null {
        if (!ethers.utils.isAddress(this.tokenContractAddress)) {
          return null
        }

        return new ethers.Contract(this.tokenContractAddress, ERC20_ABI, this.multicall)
      },
      factoryContract(): ethers.Contract {
        return new ethers.Contract(this.$store.getters.ecosystem.launchPadFactoryAddress, LAUNCHPAD_FACTORY_ABI, this.multicall)
      },
      factoryContractSigner(): ethers.Contract | null {
        if (!this.web3) {
          return null
        }

        return this.factoryContract.connect(this.web3)
      },
      isTokenContractLoading(): boolean {
        if (!this.address) {
          // complete loading early when wallet is not connected
          return ethers.utils.isAddress(this.tokenContractAddress) && this.tokenSymbol === null
        }

        // userTokenAllowance is the last thing to be set during load
        return ethers.utils.isAddress(this.tokenContractAddress) && this.userTokenAllowance === null
      },
      isApproveComplete(): boolean {
        if (this.userTokenAllowance === null) {
          return false
        }

        const maxApprove = ethers.BigNumber.from(APPROVE_AMOUNT)
        return this.userTokenAllowance.eq(maxApprove)
      },
      displayedTokenSupply(): string | null {
        if (!this.tokenSupply || !this.tokenDecimals) {
          return null
        }

        return ethers.utils.formatUnits(this.tokenSupply, this.tokenDecimals)
      },
      presaleCurrency(): string {
        return this.$store.getters.ecosystem.ethName
      },
      ecosystemName() : string {
        if (this.$store.getters.ecosystem.chainId == 56) {
          return 'bsc'
        }
        if (this.$store.getters.ecosystem.chainId == 1285) {
          return 'moonriver'
        }
        if (this.$store.getters.ecosystem.chainId == 1284) {
          return 'moonbeam'
        }
        return 'undefined'
      }
    },
    async mounted() {
      while (this.active) {
        try {
          await this.sync()
        } catch (e) {
          console.error(e)
        }

        await delay(3000)
      }
    },
    beforeRouteLeave(to, from, next) {
      this.active = false
      next()
    },
    beforeDestroy() {
      this.active = false
    },
    methods: {
      importPresale() {
        const presaleContractAddress = this.presaleContractAddress
        const chain = this.$store.getters.ecosystem.routeName

        const presaleConfig = {
          logo: this.logoUrl,
          name: this.tokenName,
          ticker: this.tokenSymbol,
          presaleLink: `/${chain}/presale/${presaleContractAddress}`
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleConfig.presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }

        this.$store.state.userProfile.importedPresales[this.ecosystemId].push(presaleConfig)


        setTimeout(() => this.sync())
      },
      validHardCap() {
        if (this.presaleHardCap == '') {
          return ['Choose the hard cap of the presale']
        }
        if (parseFloat(this.presaleHardCap) < 0.1) {
          return ['The hard cap is too low']
        }
        if (this.presaleHardCap.length > 10 || parseFloat(this.presaleHardCap) > 100000000) {
          return ['The hard cap is unreasonably high']
        }
        if ( !(/^(?![0.]+$)\d+(\.\d{1,10})?$/gm.test(this.presaleHardCap)) ) {
          return ['Input a valid positive number']
        }
        if (this.userTokenBalance && parseFloat(ethers.utils.formatUnits(this.userTokenBalance, this.tokenDecimals!)) < parseFloat(this.presaleTokenAmount)!) {
          return ['You don\'t have enough ' + this.tokenSymbol + ' tokens to launch this presale']
        }

        return [true]
      },
      validTokenAmount() {
        if (this.presaleTokenAmount == '') {
          return ['Choose the amount of tokens to provide']
        }
        if (parseFloat(this.presaleHardCap) < 0.1) {
          return ['The amount provided is too low']
        }
        if ( !(/^(?![0.]+$)\d+(\.\d{1,10})?$/gm.test(this.presaleHardCap)) ) {
          return ['Input a valid positive number']
        }
        if (this.userTokenBalance && parseFloat(ethers.utils.formatUnits(this.userTokenBalance, this.tokenDecimals!)) < parseFloat(this.presaleTokenAmount)!) {
          return ['You don\'t have enough ' + this.tokenSymbol + ' tokens to launch this presale']
        }

        return [true]
      },
      validPresalePrice() {
        if (this.presalePrice == '') {
          return ['Choose the price of your tokens during presale']
        }
        if (!(/^(?![0.]+$)\d+(\.\d{1,20})?$/gm.test(this.presalePrice)) ) {
          return ['Input a valid positive number']
        }
        if (parseFloat(this.presalePrice) < 10) {
          return ['You must provide at least 10 ' + this.tokenSymbol + ' tokens per ' + this.presaleCurrency]
        }
        if (this.userTokenBalance && parseFloat(ethers.utils.formatUnits(this.userTokenBalance, this.tokenDecimals!)) < parseFloat(this.presaleTokenAmount)!) {
          return ['You don\'t have enough ' + this.tokenSymbol + ' tokens to launch this presale']
        }

        return [true]
      },
      async approve() {
        const tokenContract = this.tokenContract!.connect(this.web3!)
        const tx = await tokenContract.populateTransaction.approve(this.presaleContractAddress, APPROVE_AMOUNT)
        await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      },
      async submit() {
        if (!this.factoryContractSigner) {
          this.requestConnect()
          return 
        }

        const form = this.$refs.form as any
        if (!form.validate()) {
          return
        }

        const presaleContractAddress = this.presaleContractAddress
        const buyLimit = ethers.utils.parseEther(this.presaleMaxContribution.toString())
        const hardCap = ethers.utils.parseEther(this.presaleHardCap)
        const tokensPerEth = parseFloat(this.presalePrice)
        const durationTime = parseFloat(this.presaleDuration.toString()) * 60 * 60
        const presaleInfo = JSON.stringify({
          tokenLogoUrl: this.logoUrl,
          telegramUrl: this.telegramUrl,
          discordUrl: this.discordUrl,
          websiteUrl: this.websiteUrl,
          isPublic: this.isPublic
        })

        const tx = await this.factoryContractSigner.populateTransaction.createPresale(
          this.tokenContract!.address,
          buyLimit,
          hardCap,
          tokensPerEth,
          durationTime,
          this.enableReferrals,
          presaleInfo
        )
        const succeeded = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        if (succeeded) {
          this.importPresale()
          const chain = this.$store.getters.ecosystem.routeName
          this.$router.push(`/${chain}/presale/${presaleContractAddress}`)
        }
      },
      copyAddress (address : string) {
        let textArea = document.createElement("textarea")
        textArea.value = address
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        let successful = document.execCommand('copy')

        document.body.removeChild(textArea)
      },
      async sync() {
        await this.syncLock.acquireAsync()
        try {
          await this.syncInternal()
        } finally {
          this.syncLock.release()
        }
      },
      async syncInternal() {
        const tokenContract = this.tokenContract
        if (!tokenContract) {
          return
        }

        const contractCode = await this.multicall.getCode(tokenContract.address)
        if (contractCode == '0x') {
          this.tokenContractError = 'Address is either not a valid ERC20 contract or not on ' + this.$store.getters.ecosystem.routeName + ' network.\nMake sure that you selected the correct ecosystem.'
          return
        }

        const contractData = {
          presaleContractAddress: <string | null> null,
          tokenName: <string | null> null,
          tokenSymbol: <string | null> null,
          tokenSupply: <ethers.BigNumber | null> null,
          tokenDecimals: <number | null> null,
          userTokenAllowance: <ethers.BigNumber | null> null,
          userTokenBalance: <ethers.BigNumber | null> null
        }

        // TODO: check if address is a valid ERC20
        const promises = [
          this.factoryContract.getNextPresaleAddress(tokenContract.address).then((a: string) => contractData.presaleContractAddress = a),
          tokenContract.name().then((n: string) => contractData.tokenName = n),
          tokenContract.symbol().then((s: string) => contractData.tokenSymbol = s),
          tokenContract.totalSupply().then((s: ethers.BigNumber) => contractData.tokenSupply = s),
          tokenContract.decimals().then((d: number) => contractData.tokenDecimals = d)
        ]
        if (this.address) {
          promises.push(
            tokenContract.balanceOf(this.address).then((b: ethers.BigNumber) => contractData.userTokenBalance = b)
          )
        }
        await Promise.all(promises)
        if (this.address) {
          contractData.userTokenAllowance = await tokenContract.allowance(this.address, contractData.presaleContractAddress)
        }

        if (tokenContract.address == this.tokenContract?.address) {
          Object.assign(this, contractData)
        }
      },
      ...mapActions(['requestConnect', 'safeSendTransaction'])
    },
    watch: {
      presaleTokenAmount: function(newAmount) {
        let soldAmount : number = (parseFloat(this.presaleTokenAmount) / 1.72) * 1.0
        this.presalePrice = (soldAmount / parseFloat(this.presaleHardCap)).toFixed(0).toString()
      },
      presaleHardCap: function(newSupply) {
        this.presaleSoftCap = parseFloat(this.presaleHardCap) * 0.25
        let soldAmount : number = (parseFloat(this.presaleTokenAmount) / 1.72) * 1.0
        this.presalePrice = (soldAmount / parseFloat(this.presaleHardCap)).toString()
      },
      tokenContractAddress(val) {
        this.tokenContractError = null
        if (!ethers.utils.isAddress(val)) {
          this.presaleContractAddress = null,
          this.tokenName = null
          this.tokenSymbol = null
          this.tokenSupply = null
          this.tokenDecimals = null
          this.userTokenAllowance = null
          this.userTokenBalance = null
          return
        }

        setTimeout(() => this.sync())
      },
      ecosystemId() {
        this.tokenContractAddress = ''
      }
    }
  })
